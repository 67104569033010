@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Oxygen", "Fira Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-animation: slideInDown 0.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s backwards;
          animation: slideInDown 0.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s backwards;
  z-index: 2;
}

.NavBar #nav-card {
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0 32px 0;
  max-height: 128px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0 2px 8px -6px #707070;
  width: 608px;
}

@media (max-width: 1440px) {
  .NavBar #nav-card {
    width: 512px;
  }
}

@media (max-width: 1024px) {
  .NavBar #nav-card {
    width: 480px;
  }
}

@media (max-width: 768px) {
  .NavBar #nav-card {
    width: 400px;
  }
}

.NavBar #nav-card .nav-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NavBar #nav-card .nav-btn-wrapper .nav-btn {
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 16px 16px 0 0;
  color: #2d2d2d;
  font-family: "Fira Sans";
  text-transform: uppercase;
  letter-spacing: 0.8px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 768px) {
  .NavBar #nav-card .nav-btn-wrapper .nav-btn {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
  }
}

.NavBar #nav-card .nav-btn-wrapper .nav-btn.nav-btn-unselected:hover {
  cursor: pointer;
  background-color: whitesmoke;
  -webkit-transition: background-color 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  transition: background-color 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.NavBar #nav-card .nav-btn-wrapper .nav-btn-underline {
  height: 2px;
  background-color: #64de7f;
  -webkit-animation: underline-appear 0.5s cubic-bezier(0.25, 1, 0.5, 1) forwards;
          animation: underline-appear 0.5s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

@-webkit-keyframes underline-appear {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes underline-appear {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/*# sourceMappingURL=NavBar.css.map */
.site-content {
  -webkit-animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
          animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  padding-top: 176px;
  padding-bottom: 176px;
}

@media (max-width: 768px) {
  .site-content {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

.card {
  display: inline-block;
  width: auto;
  height: auto;
  background-color: #ffffff;
  padding: 48px;
  border-radius: 64px;
  /*
  // Neumorphism
  background-color: $color-lightgray;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
  */
}

@media (max-width: 768px) {
  .card {
    padding: 24px;
    border-radius: 32px;
  }
}

.card.card-top {
  border-radius: 64px 64px 0 0;
}

@media (max-width: 768px) {
  .card.card-top {
    border-radius: 32px 32px 0 0;
  }
}

.card.card-bottom {
  border-radius: 0 0 64px 64px;
}

@media (max-width: 768px) {
  .card.card-bottom {
    border-radius: 0 0 32px 32px;
  }
}

.badge {
  position: relative;
  margin-left: 32px;
  padding: 0.63em 1em;
  bottom: 0.63em;
  background-color: #debb90;
  border-radius: 0.5em;
  display: inline-block;
  color: #ffffff;
  font-size: 25px;
  font-family: "Fira Sans";
  font-weight: normal;
  letter-spacing: 0.4px;
}

@media (max-width: 1440px) {
  .badge {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .badge {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .badge {
    font-size: 13px;
    margin-left: 16px;
  }
}

a.btn {
  display: inline-block;
  border-radius: 10rem;
  font-family: "Fira Sans";
  text-align: center;
  align-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 1024px) {
  a.btn {
    line-height: 16px;
  }
}

a.btn:hover {
  cursor: pointer;
}

a.btn.btn-lg {
  background-color: #64de7f;
  padding: 1.6em 2.56em;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

a.btn.btn-lg:hover {
  background-color: #499159;
}

a.btn.btn-sm {
  background-color: #ffffff;
  padding: 1em 1.6em;
  color: #707070;
}

a.btn.btn-sm:hover {
  color: #2d2d2d;
}

a.btn.btn-sm.btn-selected {
  background-color: #64de7f;
  color: #ffffff;
}

.exit-btn {
  font-size: 40px;
  padding: 8px 14px;
  position: absolute;
  top: 48px;
  right: 48px;
  color: #707070;
}

@media (max-width: 768px) {
  .exit-btn {
    top: 16px;
    right: 16px;
  }
}

.exit-btn:hover {
  background-color: #eae7ee;
  color: #2d2d2d;
  border-radius: 50%;
  box-shadow: -4px -4px 6px 4px rgba(255, 255, 255, 0.3) inset, 2px 2px 4px 4px rgba(0, 0, 0, 0.25) inset;
}

.page .filter-container {
  margin-bottom: 64px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 768px) {
  .page .filter-container {
    margin-bottom: 32px;
  }
}

.page .filter-container .options-wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.page .filter-container .options-wrapper span {
  font-family: "Fira Sans";
  color: #a37bdf;
}

.page .filter-container .options-wrapper .btn-sm {
  margin-left: 16px;
}

.page .entries-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.entry {
  -webkit-animation: zoomIn 0.5s cubic-bezier(0.25, 1, 0.5, 1) backwards;
          animation: zoomIn 0.5s cubic-bezier(0.25, 1, 0.5, 1) backwards;
}

.entry:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.entry:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.entry:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.entry:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.entry:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.entry:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.entry:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.entry:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.entry:nth-child(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.entry:nth-child(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.entry:nth-child(11) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.entry:nth-child(12) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.entry:nth-child(13) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.entry:nth-child(14) {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

.entry:nth-child(15) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.entry:nth-child(16) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.prevent-scrolling {
  overflow: hidden;
}

.dark-display-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-animation: fadeIn 0.4s cubic-bezier(0.25, 1, 0.5, 1);
          animation: fadeIn 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.loader-spinner {
  position: absolute;
  top: calc(50vh - 64px);
  left: calc(50vw - 64px);
  width: 128px;
  height: 128px;
  -webkit-animation: infinite-spinning 0.5s steps(8) infinite;
          animation: infinite-spinning 0.5s steps(8) infinite;
}

@-webkit-keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/*# sourceMappingURL=common.css.map */
.SocialBar {
  position: fixed;
  bottom: 0;
  -webkit-animation: slideInUp 0.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s backwards;
          animation: slideInUp 0.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s backwards;
  z-index: 2;
}

@media (max-width: 768px) {
  .SocialBar {
    width: 100%;
    left: 0;
    bottom: 0;
  }
}

.SocialBar .card#socials-card {
  display: block;
  width: 192px;
  box-shadow: 0 -2px 8px -6px #707070;
}

@media (max-width: 768px) {
  .SocialBar .card#socials-card {
    margin-left: auto;
    margin-right: auto;
  }
}

.SocialBar .card#socials-card .socials-wrapper {
  display: flex;
  justify-content: space-between;
  width: 192px;
}

.SocialBar .card#socials-card .socials-wrapper a i {
  color: #499159;
}

.SocialBar .card#socials-card .socials-wrapper a i:hover {
  color: #64de7f;
}
/*# sourceMappingURL=SocialBar.css.map */
.site-content {
  -webkit-animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
          animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  padding-top: 176px;
  padding-bottom: 176px;
}

@media (max-width: 768px) {
  .site-content {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

.card {
  display: inline-block;
  width: auto;
  height: auto;
  background-color: #ffffff;
  padding: 48px;
  border-radius: 64px;
  /*
  // Neumorphism
  background-color: $color-lightgray;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
  */
}

@media (max-width: 768px) {
  .card {
    padding: 24px;
    border-radius: 32px;
  }
}

.card.card-top {
  border-radius: 64px 64px 0 0;
}

@media (max-width: 768px) {
  .card.card-top {
    border-radius: 32px 32px 0 0;
  }
}

.card.card-bottom {
  border-radius: 0 0 64px 64px;
}

@media (max-width: 768px) {
  .card.card-bottom {
    border-radius: 0 0 32px 32px;
  }
}

.badge {
  position: relative;
  margin-left: 32px;
  padding: 0.63em 1em;
  bottom: 0.63em;
  background-color: #debb90;
  border-radius: 0.5em;
  display: inline-block;
  color: #ffffff;
  font-size: 25px;
  font-family: "Fira Sans";
  font-weight: normal;
  letter-spacing: 0.4px;
}

@media (max-width: 1440px) {
  .badge {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .badge {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .badge {
    font-size: 13px;
    margin-left: 16px;
  }
}

a.btn {
  display: inline-block;
  border-radius: 10rem;
  font-family: "Fira Sans";
  text-align: center;
  align-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 1024px) {
  a.btn {
    line-height: 16px;
  }
}

a.btn:hover {
  cursor: pointer;
}

a.btn.btn-lg {
  background-color: #64de7f;
  padding: 1.6em 2.56em;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

a.btn.btn-lg:hover {
  background-color: #499159;
}

a.btn.btn-sm {
  background-color: #ffffff;
  padding: 1em 1.6em;
  color: #707070;
}

a.btn.btn-sm:hover {
  color: #2d2d2d;
}

a.btn.btn-sm.btn-selected {
  background-color: #64de7f;
  color: #ffffff;
}

.exit-btn {
  font-size: 40px;
  padding: 8px 14px;
  position: absolute;
  top: 48px;
  right: 48px;
  color: #707070;
}

@media (max-width: 768px) {
  .exit-btn {
    top: 16px;
    right: 16px;
  }
}

.exit-btn:hover {
  background-color: #eae7ee;
  color: #2d2d2d;
  border-radius: 50%;
  box-shadow: -4px -4px 6px 4px rgba(255, 255, 255, 0.3) inset, 2px 2px 4px 4px rgba(0, 0, 0, 0.25) inset;
}

.page .filter-container {
  margin-bottom: 64px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 768px) {
  .page .filter-container {
    margin-bottom: 32px;
  }
}

.page .filter-container .options-wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.page .filter-container .options-wrapper span {
  font-family: "Fira Sans";
  color: #a37bdf;
}

.page .filter-container .options-wrapper .btn-sm {
  margin-left: 16px;
}

.page .entries-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.entry {
  -webkit-animation: zoomIn 0.5s cubic-bezier(0.25, 1, 0.5, 1) backwards;
          animation: zoomIn 0.5s cubic-bezier(0.25, 1, 0.5, 1) backwards;
}

.entry:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.entry:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.entry:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.entry:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.entry:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.entry:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.entry:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.entry:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.entry:nth-child(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.entry:nth-child(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.entry:nth-child(11) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.entry:nth-child(12) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.entry:nth-child(13) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.entry:nth-child(14) {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

.entry:nth-child(15) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.entry:nth-child(16) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.prevent-scrolling {
  overflow: hidden;
}

.dark-display-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-animation: fadeIn 0.4s cubic-bezier(0.25, 1, 0.5, 1);
          animation: fadeIn 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.loader-spinner {
  position: absolute;
  top: calc(50vh - 64px);
  left: calc(50vw - 64px);
  width: 128px;
  height: 128px;
  -webkit-animation: infinite-spinning 0.5s steps(8) infinite;
          animation: infinite-spinning 0.5s steps(8) infinite;
}

@-webkit-keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Home {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 940px) {
  .Home {
    flex-direction: column;
    flex-flow: column-reverse;
    height: auto;
  }
}

.Home #intro-card .hero-title {
  margin-bottom: 16px;
}

.Home #intro-card .roles-subtitle {
  margin-bottom: 16px;
  padding-left: 32px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  border-left-style: solid;
  border-width: 2px;
  border-color: #64de7f;
}

.Home #intro-card .roles-subtitle h2 {
  font-family: "Fira Sans";
  color: #a37bdf;
  font-weight: normal;
}

.Home #hero-media-card {
  margin-left: 32px;
}

@media (max-width: 940px) {
  .Home #hero-media-card {
    margin-left: 0;
    margin-bottom: 32px;
  }
}

.Home #hero-media-card img {
  width: 400px;
  height: auto;
}

@media (max-width: 1860px) {
  .Home #hero-media-card img {
    width: 360px;
  }
}

@media (max-width: 1600px) {
  .Home #hero-media-card img {
    width: 320px;
  }
}

@media (max-width: 1556px) {
  .Home #hero-media-card img {
    width: 260px;
  }
}

@media (max-width: 1120px) {
  .Home #hero-media-card img {
    width: 170px;
  }
}

@media (max-width: 940px) {
  .Home #hero-media-card img {
    width: 144px;
  }
}

.Home table.personal-details {
  margin-left: 16px;
  font-family: "Fira Sans";
  color: #499159;
}

.Home table.personal-details tr th,
.Home table.personal-details tr td {
  padding-bottom: 16px;
}

.Home table.personal-details tr th {
  width: 32px;
}

.Home table.personal-details tr td {
  padding-left: 16px;
}

.Home table.personal-details tr td a {
  font-weight: 600;
  font-style: italic;
  color: inherit;
}

.Home table.personal-details tr td a:hover {
  color: #64de7f;
}

.Home p.welcome-text {
  margin-bottom: 48px;
  max-width: 784px;
}
/*# sourceMappingURL=Home.css.map */
.About {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.About #about-media-card-side {
  position: -webkit-sticky;
  position: sticky;
  top: 176px;
}

@media (max-width: 1060px) {
  .About #about-media-card-side {
    display: none;
  }
}

.About #about-media-card-side img {
  width: 320px;
  height: 480px;
  object-fit: cover;
}

@media (max-width: 1870px) {
  .About #about-media-card-side img {
    width: 240px;
    height: 360px;
  }
}

@media (max-width: 1800px) {
  .About #about-media-card-side img {
    width: 320px;
    height: 480px;
  }
}

@media (max-width: 1750px) {
  .About #about-media-card-side img {
    width: 240px;
    height: 360px;
  }
}

.About #about-media-card-mid {
  display: none;
  -ms-grid-row-align: center;
  align-self: center;
  margin-bottom: 16px;
}

@media (max-width: 1060px) {
  .About #about-media-card-mid {
    display: inline-block;
  }
}

.About #about-media-card-mid img {
  width: 320px;
  height: 480px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .About #about-media-card-mid img {
    width: 240px;
    height: 360px;
  }
}

.About .about-info-wrapper {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1060px) {
  .About .about-info-wrapper {
    margin-left: 0;
  }
}

.About .about-info-wrapper #about-main-card {
  margin-bottom: 16px;
}

.About .about-info-wrapper #about-main-card p {
  margin-left: 16px;
}

.About .about-info-wrapper #about-main-card b {
  color: #a37bdf;
}

.About .about-info-wrapper #about-main-card .skills-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 1200px) {
  .About .about-info-wrapper #about-main-card .skills-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 1060px) {
  .About .about-info-wrapper #about-main-card .skills-wrapper {
    flex-direction: row;
  }
}

.About .about-info-wrapper #about-main-card .skills-wrapper p,
.About .about-info-wrapper #about-main-card .skills-wrapper b {
  margin-left: 0;
  color: #499159 !important;
}

.About .about-info-wrapper .about-sub-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

@media (max-width: 900px) {
  .About .about-info-wrapper .about-sub-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.About .about-info-wrapper .about-sub-wrapper .card {
  padding: 32px;
}

@media (max-width: 1060px) {
  .About .about-info-wrapper .about-sub-wrapper .card {
    padding-bottom: 160px;
  }
}

@media (max-width: 900px) {
  .About .about-info-wrapper .about-sub-wrapper .card {
    padding: 32px;
  }
}

@media (max-width: 900px) {
  .About .about-info-wrapper .about-sub-wrapper .card:first-child {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media (max-width: 1600px) {
  .About .about-info-wrapper .about-sub-wrapper .card p {
    font-size: 16px !important;
    line-height: 32px;
  }
}

@media (max-width: 1300px) {
  .About .about-info-wrapper .about-sub-wrapper .card p {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .About .about-info-wrapper .about-sub-wrapper .card p {
    font-size: 16px !important;
  }
}

.About .about-info-wrapper .about-sub-wrapper .card h2 {
  text-align: center;
}

.About .about-info-wrapper .about-sub-wrapper #education-card {
  margin-right: 8px;
}

.About .about-info-wrapper .about-sub-wrapper #interests-card {
  margin-left: 8px;
}

.About .about-info-wrapper .about-sub-wrapper #interests-card p {
  text-align: center;
}
/*# sourceMappingURL=About.css.map */
.ProjectEntry {
  margin: 16px;
}

.ProjectEntry .project-card {
  cursor: pointer;
}

.ProjectEntry .project-card:hover {
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
  -webkit-transition: -webkit-box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  -webkit-transition: box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.ProjectEntry .project-card img {
  margin-bottom: 16px;
  width: 576px;
  height: 324px;
  object-fit: cover;
  border: solid 1px #eae7ee;
  border-radius: 8px;
}

@media (max-width: 1670px) {
  .ProjectEntry .project-card img {
    width: 512px;
    height: 288px;
  }
}

@media (max-width: 1670px) {
  .ProjectEntry .project-card img {
    width: 464px;
    height: 261px;
  }
}

@media (max-width: 1320px) {
  .ProjectEntry .project-card img {
    width: 400px;
    height: 225px;
  }
}

@media (max-width: 1320px) {
  .ProjectEntry .project-card img {
    width: 384px;
    height: 216px;
  }
}

@media (max-width: 768px) {
  .ProjectEntry .project-card img {
    width: 256px;
    height: 144px;
  }
}

.ProjectEntry .project-card .project-card-sub-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ProjectEntry .project-card .project-card-sub-wrapper .badge {
  margin-left: 16px;
}

@media (max-width: 768px) {
  .ProjectEntry .project-card .project-card-sub-wrapper .badge {
    margin-left: 8px;
  }
}
/*# sourceMappingURL=ProjectEntry.css.map */
@media (max-width: 1160px) {
  .Work #work-filter {
    flex-direction: column;
  }
}

.Work .dark-display-screen {
  overflow: scroll;
}

.Work .dark-display-screen .full-project-card {
  display: block;
  position: relative;
  padding: 112px;
  -webkit-animation: slideInDown 0.5s cubic-bezier(0.25, 1, 0.5, 1);
          animation: slideInDown 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  margin: 0 192px 0 192px;
}

@media (max-width: 1800px) {
  .Work .dark-display-screen .full-project-card {
    margin: 0 128px 0 128px;
  }
}

@media (max-width: 1500px) {
  .Work .dark-display-screen .full-project-card {
    margin: 0 64px 0 64px;
  }
}

@media (max-width: 768px) {
  .Work .dark-display-screen .full-project-card {
    margin: 0 16px 0 16px;
    padding: 56px;
  }
}

.Work .dark-display-screen .full-project-card .full-project-content-container {
  display: flex;
}

@media (max-width: 1200px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container {
    flex-direction: column;
    flex-flow: column-reverse;
  }
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col {
  display: flex;
  flex-direction: column;
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left {
  align-items: center;
  justify-content: space-between;
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 640px;
  height: 360px;
  object-fit: cover;
  border: solid 1px #eae7ee;
  border-radius: 8px;
}

@media (max-width: 1750px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 560px;
    height: 315px;
  }
}

@media (max-width: 1600px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 512px;
    height: 288px;
  }
}

@media (max-width: 1400px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 448px;
    height: 252px;
  }
}

@media (max-width: 1300px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 384px;
    height: 226px;
  }
}

@media (max-width: 1200px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 256px;
    height: 144px;
  }
}

@media (max-width: 1200px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 560px;
    height: 315px;
  }
}

@media (max-width: 1000px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 512px;
    height: 288px;
  }
}

@media (max-width: 860px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 448px;
    height: 252px;
  }
}

@media (max-width: 750px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 384px;
    height: 226px;
  }
}

@media (max-width: 640px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-left img {
    width: 256px;
    height: 144px;
  }
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right {
  padding-left: 80px;
}

@media (max-width: 1200px) {
  .Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right {
    padding: 0;
  }
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right h2 {
  font-family: "Fira Sans";
  font-weight: 400;
  color: #707070;
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right table {
  font-family: "Fira Sans";
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right table tr th,
.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right table tr td {
  padding-bottom: 16px;
}

.Work .dark-display-screen .full-project-card .full-project-content-container .full-project-content-col#col-right table tr th {
  padding-right: 16px;
  color: #a37bdf;
  text-align: left;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
/*# sourceMappingURL=Work.css.map */
.ArtEntry img {
  margin: 16px;
  width: 336px;
  height: 336px;
  object-fit: cover;
}

@media (max-width: 1440px) {
  .ArtEntry img {
    width: 320px;
    height: 320px;
  }
}

@media (max-width: 1024px) {
  .ArtEntry img {
    width: 288px;
    height: 288px;
  }
}

@media (max-width: 768px) {
  .ArtEntry img {
    margin: 8px;
    width: 160px;
    height: 160px;
  }
}

.ArtEntry img:hover {
  margin: 8px;
  width: 352px;
  height: 352px;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
  -webkit-transition: margin 0.6s cubic-bezier(0.25, 1, 0.5, 1), width 0.6s cubic-bezier(0.25, 1, 0.5, 1), height 0.6s cubic-bezier(0.25, 1, 0.5, 1), -webkit-box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  -webkit-transition: margin 0.6s cubic-bezier(0.25, 1, 0.5, 1), width 0.6s cubic-bezier(0.25, 1, 0.5, 1), height 0.6s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: margin 0.6s cubic-bezier(0.25, 1, 0.5, 1), width 0.6s cubic-bezier(0.25, 1, 0.5, 1), height 0.6s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (max-width: 1440px) {
  .ArtEntry img:hover {
    margin: 8px;
    width: 336px;
    height: 336px;
  }
}

@media (max-width: 1024px) {
  .ArtEntry img:hover {
    margin: 8px;
    width: 304px;
    height: 304px;
  }
}

@media (max-width: 768px) {
  .ArtEntry img:hover {
    margin: 4px;
    width: 168px;
    height: 168px;
  }
}
/*# sourceMappingURL=ArtEntry.css.map */
/* No CSS *//*# sourceMappingURL=ArtDisplay.css.map */
@media (max-width: 800px) {
  .Art #art-filter {
    flex-direction: column;
  }
}

.Art .dark-display-screen {
  overflow: scroll;
}

.Art .dark-display-screen .exit-btn {
  color: #ffffff;
  z-index: 2;
}

.Art .dark-display-screen .exit-btn:hover {
  color: #2d2d2d;
}

.Art .dark-display-screen .art-display-container {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  max-width: 60vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  -webkit-animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
          animation: fadeIn 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.Art .dark-display-screen .art-display-container img {
  max-height: 75vh;
  max-width: 70vw;
}

.Art .dark-display-screen .art-display-container .art-plaque {
  margin-top: 16px;
}

.Art .dark-display-screen .art-display-container .art-plaque h2,
.Art .dark-display-screen .art-display-container .art-plaque h3 {
  color: #ffffff;
  font-weight: 400;
}

.Art .dark-display-screen .art-display-container .art-plaque h3 {
  font-family: "Fira Sans";
}
/*# sourceMappingURL=Art.css.map */
body {
  background-color: #eae7ee;
  overflow-y: scroll;
  /* Add the ability to scroll */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.App {
  padding: 0 192px 0 192px;
  font-size: 25px;
}

@media (max-width: 1800px) {
  .App {
    padding: 0 128px 0 128px;
  }
}

@media (max-width: 1500px) {
  .App {
    padding: 0 64px 0 64px;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 0 16px 0 16px;
  }
}

@media (max-width: 1440px) {
  .App {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .App {
    font-size: 16px;
  }
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
  font-family: "Oxygen";
  color: #2d2d2d;
  margin: 0;
}

.App h1 {
  font-size: 64px;
}

@media (max-width: 1440px) {
  .App h1 {
    font-size: 52px;
  }
}

@media (max-width: 1024px) {
  .App h1 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .App h1 {
    font-size: 33px;
  }
}

.App h2 {
  font-size: 40px;
}

@media (max-width: 1440px) {
  .App h2 {
    font-size: 33px;
  }
}

@media (max-width: 1024px) {
  .App h2 {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .App h2 {
    font-size: 20px;
  }
}

.App p {
  font-family: "Fira Sans";
  font-size: 25px;
  color: #707070;
  letter-spacing: 0.4px;
  line-height: 48px;
  margin: 16px 0;
}

@media (max-width: 1440px) {
  .App p {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .App p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media (max-width: 768px) {
  .App p {
    font-size: 13px;
  }
}

.App a {
  text-decoration: none;
}
/*# sourceMappingURL=App.css.map */
